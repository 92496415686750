const state = {
    loading: false,
}

const getters = {
    loading: (state) => state.loading,
}

const actions = {
    setLoadingState({commit}, loading) {
        commit('SET_LOADING_STATE', loading)
    }
}

const mutations = {
    SET_LOADING_STATE(state, value) {
        state.loading = value
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
