import Vue from "vue";
import dayjs from "dayjs";
const numeral = require("numeral");

require('dayjs/locale/de')
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat)
const objectSupport = require("dayjs/plugin/objectSupport");
dayjs.extend(objectSupport);
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

dayjs.locale('de')

numeral.register('locale', 'de', {
    delimiters: {
        thousands: ' ',
        decimal: ','
    },
});
numeral.locale('de')

Vue.filter("date", date => dayjs(date).format('DD.MM.YYYY'))
Vue.filter("dateDiffDays", date => dayjs(date).fromNow(true))
Vue.filter("time", date => dayjs(date).format('HH:mm'))
Vue.filter("datetime", date => dayjs(date).format('DD.MM.YYYY HH:mm'))
Vue.filter("url", (url) => {
    if(!url) { return url }
    else { return new URL(url).hostname }
})
Vue.filter("float_de", number => numeral(number).format("0.000"))
