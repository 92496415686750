import axios from 'axios'

const state = {
    viruses: null,
}

const getters = {
    viruses: (state) => state.viruses,
}

const actions = {
    async getViruses({commit}, site) {
        await axios.get('virusList/' + site.shortname)
            .then((response) => {
                commit('SET_VIRUSES', response.data)
            })
    },
    resetViruses({commit}) {
        commit('RESET_VIRUSES')
    }
}

const mutations = {
    SET_VIRUSES(state, value) {
        state.viruses = value
    },
    RESET_VIRUSES(state) {
        state.viruses = null
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
