import axios from 'axios'

const state = {
    processes: null,
}

const getters = {
    processes: (state) => state.processes,
}

const actions = {
    async getProcesses({commit}, site) {
        await axios.get('processList/' + site.shortname)
            .then((response) => {
                commit('SET_PROCESSES', response.data)
            })
    },
    resetProcesses({commit}) {
        commit('RESET_PROCESSES')
    }
}

const mutations = {
    SET_PROCESSES(state, value) {
        state.processes = value
    },
    RESET_PROCESSES(state) {
        state.processes = null
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
