<template>
  <b-modal title="Links" size="lg" id="linksModal" ok-only ok-title="Schließen" ok-variant="primary" content-class="mt-5">
    <b-container fluid>
      <b-row align-v="center">
        <b-col>
          <b-container fluid class="my-4">
            <b-card class="card">
              <b-list-group flush>
                <b-list-group-item :class="listGroupItemClasses" href="https://desk.alfanetz.de" target="_blank">
                  alfaDesk
                  <b-icon icon="building" aria-hidden="true"/>
                </b-list-group-item>
                <b-list-group-item :class="listGroupItemClasses" href="https://share.alfanetz.de/index.php/login" target="_blank">
                  alfaShare
                  <b-icon icon="share-fill" aria-hidden="true"/>
                </b-list-group-item>
                <b-list-group-item :class="listGroupItemClasses" href="https://doku.alfanetz.de/" target="_blank">
                  alfaDoku
                  <b-icon icon="journal-bookmark-fill" aria-hidden="true"/>
                </b-list-group-item>
                <b-list-group-item :class="listGroupItemClasses" href="https://remoteaccess.alfanetz.de/" target="_blank">
                  remoteAccess
                  <b-icon icon="display-fill" aria-hidden="true"/>
                </b-list-group-item>
                <b-list-group-item :class="listGroupItemClasses" href="https://app.alfaview.com/#/ldap-login/alfatraining-schulung" target="_blank">
                  alfaView Schulung
                  <b-icon icon="camera-video-fill" aria-hidden="true"/>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-container>
        </b-col>
        <b-col>
          <b-container fluid class="my-4">
            <b-card class="card">
              <b-list-group flush>
                <b-list-group-item :class="listGroupItemClasses" href="https://ams.alfanetz.de/ams" target="_blank">
                  AMS
                  <b-icon icon="person-lines-fill" aria-hidden="true"/>
                </b-list-group-item>
                <b-list-group-item :class="listGroupItemClasses" href="https://owa.alfanetz.de/" target="_blank">
                  OWA
                  <b-icon icon="mailbox2" aria-hidden="true"/>
                </b-list-group-item>
                <b-list-group-item :class="listGroupItemClasses" href="https://git.alfanetz.de/" target="_blank">
                  GitLab
                  <b-icon icon="code-slash" aria-hidden="true"/>
                </b-list-group-item>
                <b-list-group-item :class="listGroupItemClasses" href="https://kanban.alfanetz.de/" target="_blank">
                  Kanban
                  <b-icon icon="kanban-fill" aria-hidden="true"/>
                </b-list-group-item>
                <b-list-group-item :class="listGroupItemClasses" href="https://app.alfaview.com/#/ldap-login/alfatraining-internal" target="_blank">
                  alfaView Internal
                  <b-icon icon="camera-video-fill" aria-hidden="true"/>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "LinkModal",
  computed: {
    ...mapGetters(['userThemeLight']),
    listGroupItemClasses() {
      if(this.userThemeLight) {
        return ['card-row', 'text-center', 'mb-1', 'rounded', 'text-dark', 'footer-text-size']
      }
      return ['card-row', 'text-center', 'mb-1', 'rounded', 'text-white', 'footer-text-size'];
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/Footer';
</style>
