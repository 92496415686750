import axios from 'axios'

const state = {
    groups: null,
    groupsCount: 0,
    pcCount: 0,
}

const getters = {
    groups: (state) => state.groups,
    pcCount: (state) => state.pcCount,
}

const actions = {
    async getComputerList({commit}, {site, force}) {
        let groups = null
        let config = {
            params: { force_reload: force }
        }
        await axios.get('computerList/' + site.shortname, config)
            .then((response) => {
                groups = response.data
                let pcCount = 0
                response.data.forEach(element => {
                    pcCount += element.computers.length
                })
                commit('SET_GROUPS_COUNT', response.data.length)
                commit('SET_PC_COUNT', pcCount)
            })
        commit('SET_GROUPS', groups)
    },
    resetPcs({commit}) {
        commit('RESET_PCS')
    }
}

const mutations = {
    SET_GROUPS(state, value) {
        state.groups = value
    },
    SET_GROUPS_COUNT(state, value) {
        state.groupsCount = value
    },
    SET_PC_COUNT(state, value) {
        state.pcCount = value
    },
    RESET_PCS(state) {
        state.groups = null
        state.groupsCount = 0
        state.pcCount = 0
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
