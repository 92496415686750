<template>
  <div>
    <LinkModal/>
    <b-nav align="center" small>
      <b-nav-item :to="{name: 'Impressum'}" link-classes="footer-link">Impressum</b-nav-item>
      <b-nav-item :to="{name: 'Datenschutz'}" link-classes="footer-link">Datenschutzhinweis</b-nav-item>
      <b-nav-item v-if="$route.name !== 'Login' && $route.name !== 'Impressum' && $route.name !== 'Datenschutz'"
                  disabled>Infos
      </b-nav-item>
      <b-nav-item v-if="$route.name !== 'Login' && $route.name !== 'Impressum' && $route.name !== 'Datenschutz'"
                  @click="onModalShow" link-classes="footer-link">Links
      </b-nav-item>
      <b-nav-item v-if="$route.name !== 'Login' && $route.name !== 'Impressum' && $route.name !== 'Datenschutz'"
                  :to="{name: 'Feedback'}" link-classes="footer-link">Feedback
      </b-nav-item>
      <b-nav-item v-if="$route.name !== 'Login' && $route.name !== 'Impressum' && $route.name !== 'Datenschutz'"
                  href="https://doku.alfanetz.de/de/home/Schulung/Systeme/sms/changelog" target="_blank"
                  link-classes="footer-link">Changelog
      </b-nav-item>
      <b-nav-item disabled class="ml-auto">
        <span>© {{ getYear }} alfatraining Bildungszentrum GmbH. Alle Rechte vorbehalten</span>
        <span class="ml-3">Version: {{ version }}</span>
      </b-nav-item>
      <b-nav-item v-if="versionServer && version !== versionServer.version" class="ml-n4">
        <b-icon-exclamation-circle-fill :variant="iconVariantDanger" scale="1" id="tt-version" class="ml-1"/>
        <b-tooltip target="tt-version" triggers="hover">Aktuellste Version: {{ versionServer.version }}</b-tooltip>
      </b-nav-item>
    </b-nav>
  </div>
</template>

<script>
import dayjs from "dayjs";
import axios from "axios";
import {mapGetters} from "vuex";
import LinkModal from "@/components/structure/LinkModal.vue";

export default {
  name: "Footer",
  components: {LinkModal},
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      versionServer: null,
    }
  },
  methods: {
    dayjs,
    onModalShow() {
      this.$bvModal.show('linksModal')
    }
  },
  async created() {
    await axios.get(process.env.VUE_APP_API_VERSION)
        .then((response) => {
          this.versionServer = response.data
        })
  },
  computed: {
    ...mapGetters(['userColorAccessibility']),
    getYear() {
      return dayjs().get('year')
    },
    iconVariantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/Footer.scss';
</style>
