import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import axios from "axios";
import Clipboard from 'vue-clipboard2'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import './modules/filters'


import './assets/styles/App.scss'
import './assets/styles/Toasts.scss'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Clipboard)

Vue.config.productionTip = false
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_FRONTEND
axios.defaults.headers.post = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}
axios.defaults.headers.put = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}
axios.defaults.headers.get = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}
if (JSON.parse(localStorage.getItem('vuex'))) {
    axios.defaults.headers.common = {
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('vuex')).auth.token
    }
}

Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_URL,
    environment: process.env.VUE_APP_ENV,
    release: 'vue-' + process.env.VUE_APP_ENV + '@' + process.env.VUE_APP_VERSION,
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: [process.env.VUE_APP_SENTRY_TRACING_ORIGIN, /^\//],
        }),
    ],
    tracesSampleRate: parseFloat(process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE),
    sampleRate: parseFloat(process.env.VUE_APP_SENTRY_SAMPLE_RATE),
    maxBreadcrumbs: parseInt(process.env.VUE_APP_SENTRY_MAX_BREADCRUMBS),
});

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
