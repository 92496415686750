import axios from 'axios'
import _ from 'lodash'

const state = {
    sites: null,
    teams: null,
    siteSelected: null,
    teamSelected: null
}

const getters = {
    sites: (state) => state.sites,
    teams: (state) => state.teams,
    siteSelected: (state) => state.siteSelected,
    teamSelected: (state) => state.teamSelected
}

const actions = {
	fetchSite({state}, shortname) {
		return _.find(state.sites, (site) => {
			if(site.shortname === shortname) {
				return true
			}
		})
	},
    async getSites({commit}) {
        await axios.get('sites')
            .then((response) => {
                commit('SET_SITES', response.data)
            })
    },
    async getTeams({commit}) {
        await axios.get('teams')
            .then((response) => {
                commit('SET_TEAMS', response.data)
            })
    },
    selectSite({commit, state, dispatch}, shortname) {
        let site = _.find(state.sites, (site) => {
            if (site.shortname === shortname) {return true}
        })
        dispatch('selectTeam', site.team.shortname)
        commit('SET_SITE_SELECTED', site)
    },
    resetSelectedSite({commit}) {
        commit('RESET_SELECTED_SITE')
    },
    selectTeam({commit, state}, shortname) {
        let team = _.find(state.teams, (team) => {
            if (team.shortname === shortname) {return true}
        })
        commit('SET_TEAM_SELECTED', team)
    },
    resetSelectedTeam({commit}) {
        commit('RESET_SELECTED_TEAM')
    }
}

const mutations = {
    SET_SITES(state, value) {
        state.sites = value
    },
    SET_TEAMS(state, value) {
        state.teams = value
    },
    SET_SITE_SELECTED(state, value) {
        state.siteSelected = value
    },
    SET_TEAM_SELECTED(state, value) {
        state.teamSelected = value
    },
    RESET_SELECTED_SITE(state) {
        state.siteSelected = null
    },
    RESET_SELECTED_TEAM(state) {
        state.teamSelected = null
    },
    RESET(state) {
        state.sites = null
        state.teams = null
        state.siteSelected = null
        state.teamSelected = null
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
