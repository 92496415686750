import axios from 'axios'

const state = {
    customerGroups: null,
    customerGroupsCount: 0,
    customersCount: 0,
}

const getters = {
    customerGroups: (state) => state.customerGroups,
    customerGroupsCount: (state) => state.customerGroupsCount,
    customersCount: (state) => state.customersCount,
}

const actions = {
    async getCustomerList({commit}, forceReload = false) {
        let groups = null
        let config = {
            params: { force_reload: forceReload }
        }
        await axios.get('customerList', config)
            .then((response) => {
                groups = response.data
                let customerCount = 0
                response.data.forEach(element => {
                    customerCount += element.customers.length
                })
                commit('SET_CUSTOMER_GROUPS_COUNT', response.data.length)
                commit('SET_CUSTOMER_COUNT', customerCount)
            })
        commit('SET_CUSTOMER_GROUPS', groups)
    },
    resetCustomers({commit}) {
        commit('RESET_CUSTOMERS')
    }
}

const mutations = {
    SET_CUSTOMER_GROUPS(state, value) {
        state.customerGroups = value
    },
    SET_CUSTOMER_GROUPS_COUNT(state, value) {
        state.customerGroupsCount = value
    },
    SET_CUSTOMER_COUNT(state, value) {
        state.customersCount = value
    },
    RESET_CUSTOMERS(state) {
        state.customerGroups = null
        state.customerGroupsCount = 0
        state.customersCount = 0
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
