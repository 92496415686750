import axios from 'axios'

const state = {
    software: null,
    softwareCount: 0,
}

const getters = {
    software: (state) => state.software,
    softwareCount: (state) => state.softwareCount,
}

const actions = {
    async getSoftwareList({commit}) {
        await axios.get('softwareList/all')
            .then((response) => {
                commit('SET_SOFTWARE', response.data)
                commit('SET_SOFTWARE_COUNT', response.data.length)
            })
    },
    resetSoftware({commit}) {
        commit('RESET_SOFTWARE')
    }
}

const mutations = {
    SET_SOFTWARE(state, value) {
        state.software = value
    },
    SET_SOFTWARE_COUNT(state, value) {
        state.softwareCount = value
    },
    RESET_SOFTWARE(state) {
        state.software = null
        state.softwareCount = 0
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
