import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import pcs from './modules/pcs'
import misc from './modules/misc'
import auth from './modules/auth'
import sites from './modules/sites'
import viruses from './modules/viruses'
import software from './modules/software'
import processes from './modules/processes'
import customers from './modules/customers'

Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  strict: true,
  modules: {
    auth,
    sites,
    pcs,
    software,
    processes,
    viruses,
    customers,
    misc,
  },
  plugins: [vuexLocal.plugin]
})
